// https://www.npmjs.com/package/material-icons
@import 'material-icons/iconfont/material-icons.css';

/* https://www.codeur.com/tuto/css/ajouter-police-ecriture-css/ */

/* https://fonts.google.com/?thickness=7&preview.text=dans%20notre%20atelier&preview.size=96&preview.text_type=custom */
@font-face {
  font-family: Roboto;
  font-weight: normal;
  font-style: normal;
  src: url('/assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype')
}

@font-face {
  font-family: Roboto;
  font-weight: bold;
  font-style: normal;
  src: url('/assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype')
}

@font-face {
  font-family: Roboto;
  font-weight: normal;
  font-style: italic;
  src: url('/assets/fonts/Roboto/Roboto-Italic.ttf') format('truetype')
}

@font-face {
  font-family: Roboto;
  font-weight: bold;
  font-style: italic;
  src: url('/assets/fonts/Roboto/Roboto-BoldItalic.ttf') format('truetype')
}

@font-face {
  font-family: Roboto;
  font-weight: 300;
  font-style: normal;
  src: url('/assets/fonts/Roboto/Roboto-Light.ttf') format('truetype')
}

@font-face {
  font-family: Roboto;
  font-weight: 300;
  font-style: italic;
  src: url('/assets/fonts/Roboto/Roboto-LightItalic.ttf') format('truetype')
}
