@use '@angular/material' as mat;
@use 'theme' as pf-theme;
@include mat.all-component-typographies(pf-theme.$custom-typography);
@include mat.core;
@include mat.all-component-themes(pf-theme.$theme);

// To suppress the black border around the buttons when clicked
button:focus {
  outline-style: none;
}

html, body {
  height: 100%;
}

*, ::before, ::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

a, a:visited, a:hover, a:active {
  color: inherit;
}
